import throttle from './js/throttle';

const smBreakpoint = window.matchMedia('(max-width: 767px)');
const animationDuration = 300;

import('./js/handle-sticky-header').then(({ default: handleStickyHeader }) => {
  const callHandleStickyHeader = () => {
    handleStickyHeader({
      header: document.getElementById('header'),
      scrolledClass: 'header_scrolled',
    });
  };

  window.addEventListener('scroll', throttle(callHandleStickyHeader, 300));
  window.addEventListener('resize', throttle(callHandleStickyHeader, 300));
});

// const anchorLinks = document.getElementById('header__nav-links').childNodes;
const headerNavLinks = document.getElementById('header__nav-links');
const childNodes = Array.from(headerNavLinks.childNodes);

// Filter child nodes that have an href attribute not starting with "https://".
const anchorLinks = childNodes.filter(node => {
  if (node.tagName === 'A') {
    const href = node.getAttribute('href');
    return !href || !href.startsWith('https://');
  }
  return true;
});

import('./js/handle-mobile-menu').then(({ default: handleMobileMenu }) => {
  handleMobileMenu({
    menuButton: document.getElementById('header__menu'),
    closeMenuButton: document.getElementById('header__close-menu'),
    nav: document.getElementById('header__nav'),
    showClass: 'header__nav_show',
    transitionClass: 'header__nav_transition',
    overlay: document.getElementById('header__menu-overlay'),
    showOverlayClass: 'header__menu-overlay_show',
    overlayTransitionClass: 'header__menu-overlay_transition',
    duration: animationDuration,
    anchorLinks,
  });
});

const footerAnchorLinks = Object.values(
  document
    .getElementById('footer__nav-group')
    .getElementsByClassName('footer__nav-link'),
);
const scrollToContactFormButtons = Object.values(
  document.getElementsByClassName('button_scroll-to-contact-form'),
);

import('./js/scroll-to').then(({ default: scrollTo }) => {
  const callScrollTo = (link) =>
    scrollTo({
      link,
      element: document.getElementById(
        link.attributes.href.value.split('#')[1],
      ),
    });

  anchorLinks.forEach((link) => callScrollTo(link));
  footerAnchorLinks.forEach((link) => callScrollTo(link));
  scrollToContactFormButtons.forEach((button) =>
    scrollTo({ link: button, element: document.getElementById('contact') }),
  );
});

const accordion = document.getElementById('accordion');
const accordionList = accordion.childNodes;

if (accordionList)
  import('./js/toggle-accordion').then(({ default: toggleAccordion }) => {
    accordionList.forEach((item, i) =>
      toggleAccordion({
        item,
        itemOpenClass: 'accordion__item_open',
        toggleButton: accordion.getElementsByClassName('accordion__heading')[i],
        toggleButtonOpenClass: 'accordion__heading_open',
        content: accordion.getElementsByClassName('accordion__content')[i],
        contentOpenClass: 'accordion__content_open',
      }),
    );
  });

const industries = document.getElementById('industries__list');
const industriesList = Object.values(
  industries.getElementsByClassName('industries__item'),
);

if (industries)
  import('./js/toggle-accordion').then(({ default: toggleAccordion }) => {
    industriesList.forEach((item, i) =>
      toggleAccordion({
        item,
        toggleButton: industries.getElementsByClassName('industries__arrow')[i],
        toggleButtonOpenClass: 'industries__arrow_open',
        content: industries.getElementsByClassName('industries__content')[i],
        contentOpenClass: 'industries__content_open',
      }),
    );
  });

const platformTabs = document.getElementById('platform-tabs');

if (platformTabs)
  import('./js/handle-dropdown').then(({ default: handleDropdown }) => {
    handleDropdown({
      labels: Object.values(platformTabs.getElementsByClassName('tabs__label')),
      container: platformTabs,
      containerOpenClass: 'tabs_open',
      smBreakpoint,
    });
  });

const blob = document.getElementById('blob');

document.addEventListener('DOMContentLoaded', () => {
  if (blob)
    import('./js/animate-blob').then(({ default: animateBlob }) => {
      animateBlob({ blob, blobOpacityClass: 'blob_opacity_0' });
    });
});

const contactFormId = 'contact-form';
const contactForm = document.getElementById('contact-form');
const apiUrl = process.env.API_URL;

if (contactForm) {
  import('./js/contact/index').then(({ default: handleContactForm }) =>
    handleContactForm(contactFormId, contactForm, `${apiUrl}/send-contact`),
  );
}
